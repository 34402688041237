import Glide from '@glidejs/glide'

let carousels = [];

const initCarousels = () => {
    document.querySelectorAll('.element-carousel-container .carousel.glide').forEach(el => {
        if(el.querySelectorAll('.glide__slide').length < 2){
            return;
        }

        let carousel = new Glide(el, {
            type: 'carousel',
            perView: 1.5,
            focusAt: 'center',
            gap:20
        }).mount();

        carousels.push(carousel);
    });

    document.querySelectorAll('.cigar-carousel .cigars.glide').forEach(el => {
        let carousel = new Glide(el, {
            type: 'slider',
            startAt: 0,
            focusAt:0,
            draggable:true,
            gap:48,
            perView:3,
            peek:{before:0,after:100},
            bound:true,
            breakpoints:{
                768: {
                    perView:1,
                    peek:{before:0,after:50},
                    gap:32
                }
            }
        }).mount();

        carousels.push(carousel);
    });

    document.querySelectorAll('.similar-carousel .cigars.glide, .element-social-post-container .carousel.glide').forEach(el => {
        let carousel = new Glide(el, {
            type: 'carousel',
            startAt: 0,
            focusAt:0,
            perView:3,
            draggable:true,
            gap:48,
            bound:true,
            breakpoints:{
                768: {
                    perView:1,
                    peek:{before:60,after:60},
                    gap:32
                }
            }
        }).mount();

        carousels.push(carousel);
    });

    document.querySelectorAll('.rgc .carousel.glide').forEach(el => {
        let carousel = new Glide(el, {
            type: 'carousel',
            startAt: 0,
            focusAt:0,
            perView:4,
            draggable:true,
            gap:48,
            bound:true,
            autoplay:2000,
            breakpoints:{
                768: {
                    perView:2,
                    peek:{before:60,after:60},
                    gap:32
                },
                460: {
                    perView:1,
                    peek:{before:60,after:60},
                    gap:16
                }
            }
        }).mount();

        carousels.push(carousel);
    });


};


const killCarousels = () => {
    carousels.forEach(carousel => {
        try{
            carousel.destroy();
        } catch(e){

        }
    });
};

export { initCarousels, killCarousels };


