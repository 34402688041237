<script>
export default {
    replace:false,
    methods:{
        showAll(){
            this.$refs.container.classList.add('all');
        }
    },
    mounted(){

    }
}
</script>
